import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { defaultStringValue, tableRowLimit } from '../pageHelpers';
import AttributeSelectField from './AttributeSelectField';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardSkeleton from './LeaderboardSkelton';
import LeaderboardRow from './LeaderboardTableRow';
import MetricSelectField from './MetricSelectField';
import {
  homeAcademyAnalysisDropDownOptionsState,
  homeAcademyAnalysisDropDownValuesState,
} from './pageAtoms';
import { getSession } from 'components/shared/AcademyAnalysis/pageHelpers';
import {
  LeaderboardPlayer,
  MetricLabLeaderboardPlayer,
  MetricTrainingLeaderboardPlayer,
  TrainingAttribute,
} from 'components/shared/AcademyAnalysis/types';
import ContentContainer from 'components/shared/ContentContainer';
import { trpc } from 'helpers/trpc';

interface Props {
  attributes: TrainingAttribute[];
}

const customStyles = {
  contentContainer: {
    height: '100%',
  },
  leaderboardTable: {
    pl: 4,
    pr: 3,
  },
};

const LeaderboardTable = ({ attributes }: Props) => {
  const [leaderboardPlayers, setLeaderboardPlayers] = useState<
    LeaderboardPlayer[]
  >([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const dropDownValues = useRecoilValue(homeAcademyAnalysisDropDownValuesState);

  const dropDownOptions = useRecoilValue(
    homeAcademyAnalysisDropDownOptionsState
  );

  const session = useMemo(() => {
    if (dropDownOptions.sessions && dropDownValues.sessionId) {
      return getSession(dropDownOptions.sessions, dropDownValues.sessionId);
    }

    return null;
  }, [dropDownOptions?.sessions, dropDownValues.sessionId]);

  const isLabSession = useMemo(() => {
    if (session?.type === 'Lab session') return true;

    return false;
  }, [session]);

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const metricAndStationIds = useMemo(
    () => ({
      metricId: dropDownValues.measurementId?.toString().split('+')[0],
      stationId: dropDownValues.measurementId?.toString().split('+')[1],
    }),
    [dropDownValues.measurementId]
  );

  const {
    data: trainingLeaderboard,
    refetch: refetchTrainingLeaderboard,
    isFetching: isTrainingLeaderboardFetching,
  } = trpc.academyAnalysis.getAcademyAnalysisTrainingSessionLeaderboard.useQuery(
    {
      positionGroupIds: dropDownValues.positionGroupIds,
      positionIds: dropDownValues.positionIds,
      academyTeamId: dropDownValues.teamId ?? undefined,
      drillId: parseInt(metricAndStationIds.metricId!),
    },
    { enabled: false }
  );

  const {
    data: labLeaderboard,
    refetch: refetchLabLeaderboard,
    isFetching: isLabLeaderboardFetching,
  } = trpc.academyAnalysis.getAcademyAnalysisLabSessionLeaderboard.useQuery(
    {
      positionGroupIds: dropDownValues.positionGroupIds,
      positionIds: dropDownValues.positionIds,
      metricId: metricAndStationIds.metricId,
      stationId: metricAndStationIds.stationId,
      sessionId: session?.sessionId?.toString(),
    },
    { enabled: false }
  );

  const {
    data: trainingMeasurements,
    isFetching: isTrainingMeasurementsFetching,
    refetch: refetchTrainingMeasurements,
  } = trpc.shared.getTrainingSessionMeasurements.useQuery(
    { trialId: session?.sessionId! as number },
    { enabled: false }
  );

  const {
    data: labMeasurements,
    isFetching: isLabMeasurementsFetching,
    refetch: refetchLabMeasurements,
  } = trpc.shared.getLabSessionMetrics.useQuery(
    { sessionId: session?.sessionId! as string },
    { enabled: false }
  );

  const getRankedItems = (
    list?: MetricTrainingLeaderboardPlayer[] | MetricLabLeaderboardPlayer[]
  ) => {
    const sortedList =
      list?.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.score - b.score;
        } else {
          return b.score - a.score;
        }
      }) ?? [];

    return sortedList.map((player, index) => ({
      ...player,
      rank: index + 1,
    }));
  };

  const sessionMeasurements = isLabSession
    ? labMeasurements
    : trainingMeasurements;

  const getColumnTitle = () => {
    if (sessionMeasurements && sessionMeasurements.length === 0)
      return defaultStringValue;

    return (
      sessionMeasurements?.find(
        (measurements) => measurements.id === dropDownValues.measurementId
      )?.name ?? defaultStringValue
    );
  };

  useEffect(() => {
    if (dropDownValues.measurementId) {
      if (isLabSession) {
        refetchLabLeaderboard();
      } else {
        refetchTrainingLeaderboard();
      }
    }
  }, [
    isLabSession,
    dropDownValues,
    currentUser?.club.activeClubId,
    refetchLabLeaderboard,
    refetchTrainingLeaderboard,
  ]);

  useEffect(() => {
    if (isLabSession) {
      refetchLabMeasurements();
    } else {
      refetchTrainingMeasurements();
    }
  }, [
    dropDownValues.teamId,
    dropDownValues.sessionId,
    dropDownValues.positionIds,
    dropDownValues.positionGroupIds,
    currentUser?.club.activeClubId,
    isLabSession,
    refetchTrainingMeasurements,
    refetchLabMeasurements,
  ]);

  useEffect(() => {
    if (isLabSession && labLeaderboard?.leaderboard) {
      setLeaderboardPlayers(labLeaderboard?.leaderboard ?? []);
      setSortOrder(labLeaderboard.sortOrder);
      return;
    }

    if (!isLabSession && trainingLeaderboard?.leaderboard) {
      setLeaderboardPlayers(trainingLeaderboard?.leaderboard ?? []);
      setSortOrder(trainingLeaderboard.sortOrder);
      return;
    }

    setLeaderboardPlayers([]);
  }, [isLabSession, labLeaderboard, trainingLeaderboard]);

  const leaderboardTable = (
    <TableContainer>
      <Table>
        <LeaderboardHeader selectedEntry={getColumnTitle()} />

        {(
          isLabSession
            ? isLabMeasurementsFetching || isLabLeaderboardFetching
            : isTrainingMeasurementsFetching || isTrainingLeaderboardFetching
        ) ? (
          <LeaderboardSkeleton />
        ) : (
          <TableBody>
            {leaderboardPlayers.length! > 0 ? (
              getRankedItems(leaderboardPlayers.slice(0, tableRowLimit)).map(
                (player, index) => (
                  <LeaderboardRow key={index} player={player} />
                )
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No players available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  return (
    <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
      <ContentContainer
        containerTitle="Leaderboard"
        containerRight={
          isLabSession ? (
            <MetricSelectField
              isFetching={
                isLabSession
                  ? isLabMeasurementsFetching
                  : isTrainingMeasurementsFetching
              }
              sessionMeasurements={sessionMeasurements ?? []}
            />
          ) : (
            <AttributeSelectField
              isFetching={
                isLabSession
                  ? isLabMeasurementsFetching
                  : isTrainingMeasurementsFetching
              }
              attributes={attributes}
            />
          )
        }
        sx={customStyles.contentContainer}
      >
        <Box sx={customStyles.leaderboardTable}>{leaderboardTable}</Box>
      </ContentContainer>
    </Grid>
  );
};

export default LeaderboardTable;
