import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { defaultStringValue } from '../pageHelpers';
import LeaderboardTable from './LeaderboardTable';
import {
  homeAcademyAnalysisDropDownOptionsState,
  homeAcademyAnalysisDropDownValuesState,
} from './pageAtoms';
import CardSection from 'components/shared/AcademyAnalysis/CardSection';
import commonStyles from 'components/shared/AcademyAnalysis/commonStyles';
import DataLog from 'components/shared/AcademyAnalysis/DataLog';
import DropDownSection from 'components/shared/AcademyAnalysis/DropDownSection';
import { getSession } from 'components/shared/AcademyAnalysis/pageHelpers';
import PageContainer from 'components/shared/PageContainer';
import { castActiveState } from 'state/atoms';
import { trpc } from 'helpers/trpc';

const customStyles = {
  pageContainer: {
    mt: 0.5,
  },
};

const AcademyAnalysisSection = () => {
  const castActive = useRecoilValue(castActiveState);

  const [dropDownOptions, setDropDownOptions] = useRecoilState(
    homeAcademyAnalysisDropDownOptionsState
  );
  const [dropDownValues, setDropDownValues] = useRecoilState(
    homeAcademyAnalysisDropDownValuesState
  );

  const academyAnalysisQueryProps = useMemo(() => {
    if (castActive) {
      return {
        refetchInterval: 3000 * 1,
        refetchIntervalInBackground: true,
      };
    }

    return { enabled: false };
  }, [castActive]);

  const session = useMemo(() => {
    if (dropDownOptions.sessions && dropDownValues.sessionId) {
      return getSession(dropDownOptions.sessions, dropDownValues.sessionId);
    }

    return null;
  }, [dropDownOptions?.sessions, dropDownValues.sessionId]);

  const { data: currentUser } = trpc.shared.currentUser.useQuery();

  const isLabSession = useMemo(() => {
    if (session?.type === 'Lab session') return true;
    return false;
  }, [session]);

  const {
    data: trainingDashboardData,
    refetch: refetchTrainingDashboard,
    isFetching: isTrainingDashboardFetching,
  } = trpc.academyAnalysis.getAcademyAnalysisTrainingSessionDashboard.useQuery(
    {
      trialId: parseInt(session?.sessionId.toString()!),
      positionGroupIds: dropDownValues.positionGroupIds,
      positionIds: dropDownValues.positionIds,
      academyTeamId: dropDownValues.teamId ?? undefined,
    },
    academyAnalysisQueryProps
  );

  const {
    data: labDashboardData,
    refetch: refetchLabDashboard,
    isFetching: isLabDashboardFetching,
  } = trpc.academyAnalysis.getAcademyAnalysisLabSessionDashboard.useQuery(
    {
      sessionId: session?.sessionId?.toString(),
      positionGroupIds: dropDownValues.positionGroupIds,
      positionIds: dropDownValues.positionIds,
    },
    academyAnalysisQueryProps
  );

  useEffect(() => {
    if (isLabSession) {
      refetchLabDashboard();
    } else {
      refetchTrainingDashboard();
    }
  }, [
    dropDownValues.teamId,
    dropDownValues.sessionId,
    dropDownValues.positionIds,
    dropDownValues.positionGroupIds,
    currentUser?.club.activeClubId,
    refetchTrainingDashboard,
    isLabSession,
    refetchLabDashboard,
  ]);

  return (
    <PageContainer
      pageTitle="Academy analysis"
      sx={customStyles.pageContainer}
      headerStyles={commonStyles.headerStyles}
      headerRight={
        <DropDownSection
          dropDownValues={dropDownValues}
          setDropDownValues={setDropDownValues}
          setDropDownOptions={setDropDownOptions}
        />
      }
    >
      <Grid container spacing={8}>
        <CardSection
          academyAnalysis={
            isLabSession ? labDashboardData! : trainingDashboardData!
          }
          sessionName={session?.name ?? defaultStringValue}
          isLoading={
            isLabSession ? isLabDashboardFetching : isTrainingDashboardFetching
          }
        />

        <LeaderboardTable
          attributes={
            isLabSession ? [] : trainingDashboardData?.attributeScores ?? [] // Attributes are only visible when it is a training session
          }
        />

        <DataLog
          isHome={true}
          isDataFetching={
            isLabSession ? isLabDashboardFetching : isTrainingDashboardFetching
          }
          newTrialLog={
            isLabSession
              ? labDashboardData?.newTrialLog ?? []
              : trainingDashboardData?.newTrialLog ?? []
          }
        />
      </Grid>
    </PageContainer>
  );
};

export default AcademyAnalysisSection;
